<template>
    <div>
        <section class="pt-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <h2>Privacy statement</h2>
                        <p>Persoonlijke gegevens worden door LOCOmotion BV zorgvuldig bewerkt en beveiligd. Hierbij houden wij ons aan de eisen die de Wet bescherming persoonsgegevens (Wbp) ons stelt. Omdat onze
                            gegevensverwerking binnen de wettelijke vrijstellingsregelingen valt, is deze niet aangemeld bij het College Bescherming Persoonsgegevens (CBP). In dit statement leggen wij uit welke persoonsgegevens wij verzamelen en
                            gebruiken en voor welke doeleinden. Dit statement is voor het laatst aangepast op 20 september 2019.</p>
                        <h3>Bedrijfsgegevens</h3>
                        <p>Hoewel u onze website ook als bedrijf kunt bezoeken en gebruiken, vallen bedrijfsgegevens niet onder de Wet bescherming persoonsgegevens (Wbp). Dit privacy statement is dan ook hoofdzakelijk bedoeld voor diegenen die de website
                            als particulier of als werknemer gebruiken.</p>
                        <h3>Vastleggen en verwerking van gegevens</h3>
                        <p>Door het gebruiken van onze website kunt u gegevens bij ons achterlaten. Dit kunnen persoonsgegevens zijn. Persoonsgegevens kunt u zowel op actieve als op passieve wijze aan ons verstrekken. Bij actief verstrekte gegevens gaat
                            het om gegevens die u zelf op de website invult of bijvoorbeeld per e-mail aan ons toestuurt. Daarnaast kunnen er op de website automatisch gegevens worden opgeslagen, soms zonder dat u zich daarvan bewust bent (passief
                            verstrekte gegevens). In dit privacy statement vindt u welke actief en passief verstrekte gegevens precies worden verwerkt en voor welke doeleinden. Gegevens die niet langer nodig zijn worden verwijderd. U heeft het recht om
                            te vragen om inzage in, correctie of verwijdering van uw gegevens.</p>
                        <h3>Verstrekking aan derden</h3>
                        <p>Wij verstrekken de door u aan ons verstrekte gegevens niet aan derden, tenzij dit noodzakelijk is in het kader van een overeenkomst die u met ons sluit of wanneer dit wettelijk verplicht is. In een geval van een vermoeden van
                            fraude of misbruik van onze website kunnen wij persoonsgegevens bovendien aan de bevoegde autoriteiten overhandigen.</p>
                        <h3>Nieuwsbrief</h3>
                        <p>Wij bieden een nieuwsbrief waarmee we geïnteresseerden willen informeren over onze producten, diensten en aanverwante zaken. Uw e-mailadres wordt alleen met uw expliciete toestemming toegevoegd aan de lijst van abonnees. Iedere
                            nieuwsbrief bevat bovendien een link waarmee u zich kunt afmelden. Het abonneebestand van de nieuwsbrief wordt niet aan derden verstrekt.</p>
                        <h3>Beveiliging van gegevens</h3>
                        <p>Wij maken gebruik van veiligheidsprocedures, onder meer om te voorkomen dat onbevoegden toegang krijgen tot persoonsgegevens.</p>
                        <h3>Gebruik van cookies</h3>
                        <p>Wij maken op onze website gebruik van cookies. Daarmee kunnen wij onder andere het bezoek van pagina’s van de website combineren en het gedrag van gebruikers analyseren. U kunt het gebruik van deze cookies weigeren, hoewel dit
                            de functionaliteit en het gebruiksgemak van de website kan beperken.</p>
                        <h3>Diensten van Google</h3>
                        <p>Onze website maakt gebruik van bepaalde diensten van Google waarbij gebruik kan worden gemaakt van cookies en andere technieken om bij te houden hoe gebruikers de website gebruiken. De aldus verkregen informatie wordt, met
                            inbegrip van het adres van uw computer (IP-adres), door Google opgeslagen op hun servers in de Verenigde Staten.</p>
                        <p>Google gebruikt deze informatie om bij te houden hoe onze website gebruikt wordt, om rapporten over de website te kunnen maken en om haar adverteerders informatie over de effectiviteit van campagnes te kunnen bieden. Google
                            kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover derden de informatie namens Google verwerken. Wij hebben hier geen invloed op.</p>
                        <h3>Websites van derden</h3>
                        <p>Dit privacy statement is niet van toepassing op websites van derden die door middel van links met onze website zijn verbonden. Wij kunnen niet garanderen dat deze derden op een betrouwbare of veilige manier met uw
                            persoonsgegevens omgaan. Wij raden u dan ook aan de privacyverklaring van deze websites te lezen alvorens van deze websites gebruik te maken.</p>
                        <h3>Wijziging Privacy Statement</h3>
                        <p>Wij behouden ons het recht voor om wijzigingen aan te brengen in dit privacy statement.</p>
                        <h3>Contactgegevens</h3>
                        <p>Vragen over ons privacybeleid of vragen omtrent inzage en wijzingen in (of verwijdering van) uw persoonsgegevens kunt u te allen tijde richten aan: j.j.knibbe@gmail.com. Wij staan ingeschreven bij de Kamer van Koophandel
                            Centraal Gelderland 09080.147</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'PrivacyStatement'
}
</script>
