<template>
    <div>
        <h3>Stappen</h3>
        <div class="vertical-menu mb-4">
            <router-link to="/">Basisgegevens</router-link>
            <router-link to="/stap-1">1. Transfers binnen de grenzen van de ligondersteuning (bed, brancard, etc.) of op de grond</router-link>
            <router-link to="/stap-2">2. Horizontale transfers van de ene naar de andere ligondersteuning</router-link>
            <router-link to="/stap-3">3. Transfers van en naar bed, (rol)stoel etc.</router-link>
            <router-link to="/stap-4">4. Statische belasting (in moeilijke houdingen werken)</router-link>
            <router-link to="/stap-5">5. Manoeuvreren met de brancard</router-link>
            <router-link to="/stap-6">6. Tillen van objecten</router-link>
            <router-link to="/resultaten">Jouw resultaten</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideNavigation'
}
</script>
