<template>
    <div>
        <h2>Introductie</h2>
        <p>Vul eerst de onderstaande velden in en klik daarna op ‘volgende stap’. Als je alle stappen hebt doorlopen zie je je resultaten in een staafdiagram.</p>
        <p>Als je twijfelt, of als je bepaalde getallen toch wilt aanpassen, kan dat probleemloos. Succes!</p>
        <p class="mb-5">
            Deze TilThermometer vraagt naar handelingen die bij ritten zonder echte tijdsdruk worden gedaan. Die moeten immers veilig en gezond uitgevoerd worden, dus volgens de Praktijkrichtlijnen Fysieke Belasting zoals ze in de
            Ambulancezorg zijn afgesproken. Als dat is gerealiseerd, is er meer ruimte om onvoorspelbare, potentieel zware handelingen het hoofd te kunnen bieden.
        </p>
        <h3>Vul onderstaande velden in</h3>
        <form>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                    <label for="service">Dienst</label>
                    <input type="text" v-model="service" @keyup="validateString" class="form-control mb-1" id="service"
                           placeholder="Dienst">
                    <div class="valid-feedback">
                        Dat ziet er goed uit!
                    </div>
                    <div class="invalid-feedback">
                        Je hebt dit veld nog niet ingevuld.
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="location">Standplaats</label>
                    <input type="text" v-model="location" @keyup="validateString" class="form-control mb-1" id="location" placeholder="Standplaats">
                    <div class="valid-feedback">
                        Dat ziet er goed uit!
                    </div>
                    <div class="invalid-feedback">
                        Je hebt dit veld nog niet ingevuld.
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                    <label for="amountPatients">Totaal aantal patiënten</label>
                    <input type="text" v-model="amountPatients" @keyup="validateInteger" class="form-control mb-1" id="amountPatients" placeholder="Aantal patiënten" required>
                    <div class="valid-feedback">
                        Dat ziet er goed uit!
                    </div>
                    <div class="invalid-feedback">
                        Je hebt dit veld nog niet ingevuld.
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="contactName">Ingevuld door</label>
                    <input type="text" v-model="contactName" @keyup="validateString" class="form-control mb-1" id="contactName" placeholder="Vul jouw naam in">
                    <div class="valid-feedback">
                        Dat ziet er goed uit!
                    </div>
                    <div class="invalid-feedback">
                        Je hebt dit veld nog niet ingevuld.
                    </div>
                </div>
            </div>
            <button class="btn btn-primary mt-2" @click.prevent="validateAndRedirect">Volgende stap <i class="fas fa-angle-right"></i></button>
        </form>
    </div>
</template>

<script>
export default {
    name:     'Home',
    computed: {
        service:        {
            get() {
                return this.$store.state.service;
            },
            set(value) {
                this.$store.commit('setService', value);
            }
        },
        location:       {
            get() {
                return this.$store.state.location;
            },
            set(value) {
                this.$store.commit('setLocation', value);
            }
        },
        amountPatients: {
            get() {
                return this.$store.state.amountPatients;
            },
            set(value) {
                if(!isNaN(parseFloat(value)) && isFinite(value) && parseInt(value) !== 0) {
                    this.$store.commit('setAmountPatients', value);
                }
            }
        },
        contactName:    {
            get() {
                return this.$store.state.contactName;
            },
            set(value) {
                this.$store.commit('setContactName', value);
            }
        }
    },
    methods:  {
        validateString(e) {
            if(e.key !== 'Tab') {
                if(e.target.value !== '') {
                    e.target.classList.remove('is-invalid');
                    e.target.classList.add('is-valid');
                } else {
                    e.target.classList.remove('is-valid');
                    e.target.classList.add('is-invalid');
                }

                this.$parent.showErrorBlock = false;
            }
        },
        validateInteger(e) {
            if(!isNaN(parseFloat(e.target.value)) && isFinite(e.target.value) && parseInt(e.target.value) !== 0) {
                if(e.key !== 'Tab') {
                    e.target.classList.remove('is-invalid');
                    e.target.classList.add('is-valid');
                }
            } else {
                e.target.value = 0;
                e.target.classList.remove('is-valid');
                e.target.classList.add('is-invalid');
            }
        },
        validateAndRedirect() {
            // Trigger keyup event
            document.getElementById('amountPatients').dispatchEvent(new KeyboardEvent('keyup', {key: 'Tab'}));

            if(this.amountPatients > 0) {
                this.$router.push('/stap-1');
            }
        }
    }
}
</script>
