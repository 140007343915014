<template>
    <div>
        <h2>3. Transfers van en naar bed, (rol)stoel etc.</h2>
        <p><strong>Praktijkrichtlijn Fysieke Belasting:</strong> Bij transfers met patiënten met Mobiliteitsklasse 'Beperkt' (oranje) of 'Nauwelijks' (rood) wordt, indien aanwezig, een tillift gebruikt.</p>
        <form class="mt-5">
            <h4>3.1. Het aantal keer dat deze transfer is uitgevoerd per Mobiliteitsklasse</h4>
            <p>Per patient kan sprake zijn van meer dan een transfer van en naar bed, (rol)stoel etc.
                Tel het aantal van deze transfers per Mobiliteitsklasse. Dit aantal kan dus hoger zijn dan het aantal patienten in dezelfde Mobiliteitsklasse.</p>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="3.1.goed" title="Goed" mobilityClass="goed"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="3.1.beperkt" title="Beperkt" mobilityClass="beperkt"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="3.1.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"/>
                </div>
            </div>
            <h4>3.2. Aantal transfers van en naar bed, rolstoel e.d. waarbij een tillift is gebruikt</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="3.2.goed" title="Goed" mobilityClass="goed" :maxValue="this.$store.getters.getQuestionById('3.1.goed').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="3.2.beperkt" title="Beperkt" mobilityClass="beperkt" :maxValue="this.$store.getters.getQuestionById('3.1.beperkt').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="3.2.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"
                                         :maxValue="this.$store.getters.getQuestionById('3.1.nauwelijks').value"/>
                </div>
            </div>
            <router-link to="/stap-4" class="btn btn-primary mt-5">Volgende stap <i class="fas fa-angle-right"></i></router-link>
        </form>
    </div>
</template>

<script>
import MobilityClassAnswer from "./components/MobilityClassAnswer.vue";

export default {
    name:       'StepThree',
    components: {MobilityClassAnswer},
    methods:    {
        getPercentage(sum) {
            if(isNaN(sum)) {
                return 0;
            }

            return sum;
        }
    }
}
</script>
