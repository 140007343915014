<template>
    <div>
        <section class="pt-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <p>
                        De Praktijkrichtlijnen Fysieke Belasting zijn opgesteld in opdracht van Ambulancezorg Nederland en in afstemming met CNV Zorg & Welzijn en FNV Zorg & Welzijn.
                        </p>

                        <p>
                            <strong>Werkgroep actualisatie beleidsinstrumenten fysieke belasting</strong><br>
                            D. Alkema, Veiligheids- en Gezondheidsregio Gelderland-Midden<br>
                            L. de Bree, RAV Brabant Midden-West-Noord<br>
                            J. Bruijns, Veiligheidsregio Gelderland-Zuid<br>
                            E. Huizer, Ambulancezorg GGD Haaglanden<br>
                            G. van der Kolk, Ambulance Amsterdam<br>
                            N. Sijahailatua, Ambulancezorg GGD Haaglanden<br>
                            E. Steyvers, RAV Brabant Midden-West-Noord<br>
                            R. Vergunst, Ambulance Amsterdam<br>
                            R. Verhagen, Ambulance Amsterdam
                        </p>

                        <p>
                            <strong>Tekst</strong><br>
                            NE Knibbe, JJ Knibbe (LOCOmotion)
                        </p>

                        <p>
                            <strong>Redactie</strong><br>
                            J. Rigterink, Ambulancezorg Nederland
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Copyright'
}
</script>
