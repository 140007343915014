<template>
    <div>
        <h2>Jouw resultaten</h2>
        <p class="mb-5">
            De totale hoogte van de balk geeft het percentage cliënten aan met Mobiliteitsklasse 'Beperkt' en 'Nauwelijks'. Het groene deel betreft de 'Beperkt' en 'Nauwelijks' cliënten waarbij jullie volgens de Praktijkrichtlijn Fysieke Belasting werken. Het rode deel geeft het deel aan waarbij dat niet het geval is en waarbij dus actie geboden is.
        </p>

        <h4>Basisgegevens</h4>
        <table class="table col-md-8 col-12">
            <tbody>
            <tr>
                <td>Dienst</td>
                <td>{{ service }}</td>
            </tr>
            <tr>
                <td>Standplaats</td>
                <td>{{ location }}</td>
            </tr>
            <tr>
                <td>Totaal aantal pati&euml;nten</td>
                <td>{{ amountPatients }}</td>
            </tr>
            <tr>
                <td>Ingevuld door</td>
                <td>{{ contactName }}</td>
            </tr>
            <tr>
                <td>Ingevuld op</td>
                <td>{{ currentDate }}</td>
            </tr>
            </tbody>
        </table>
        <h4 class="mt-5">resultaten-in-een-grafiek</h4>
        <vue-frappe
            ref="resultsChart"
            id="results-chart"
            :labels="['1. Ligondersteuning', '1. Glij- en rolmateriaal', '2. Ligondersteuning', '2. Glij- en rolmateriaal', '3. Tillift', '4. Statische belasting', '5. Manoeuvreren', '6. Tillen']"
            type="bar"
            :height="400"
            :colors="['#BDDB4B', '#F05B29']"
            :dataSets="this.data"
            :barOptions="{stacked: 1, spaceRatio: 0.2}"
            :axisOptions="{xAxisMode: 'span', xIsSeries: 0}"
            :valuesOverPoints="true"
            :key="resultsChartKey">
        </vue-frappe>
        <div class="chart-legend-custom">
            <span class="legend-square-green"></span><span>Volgens de praktijkrichtlijnen</span><br>
            <span class="legend-square-red"></span><span>Niet volgens de praktijkrichtlijnen</span>
        </div>
        <h4 class="mt-5">1. Transfers binnen de grenzen van de ligondersteuning (bed, brancard, etc.) of op de grond.</h4>
        <div class="form-row">
            <p>Aantal pati&euml;nten waarbij in hoogte verstelbare ligondersteuning wordt gebruikt.</p>
            <div class="form-row">
                <Result :payload="calculateAmounts(['1.2.beperkt', '1.2.nauwelijks'], ['1.1.beperkt', '1.1.nauwelijks'])"
                        label-title="Aantal patiënten in 'beperkt' en 'nauwelijks'"/>
            </div>
            <p>Aantal pati&euml;nten waarbij glij- of rolmateriaal wordt gebruikt.</p>
            <div class="form-row">
                <Result :payload="calculateAmounts(['1.3.beperkt', '1.3.nauwelijks'], ['1.1.beperkt', '1.1.nauwelijks'])"
                        label-title="Aantal patiënten in 'beperkt' en 'nauwelijks'"/>
            </div>
        </div>

        <h4 class="mt-5">2. Horizontale transfers van de ene naar de andere ligondersteuning.</h4>
        <div class="form-row">
            <p>Aantal pati&euml;nten waarbij in hoogte verstelbare ligondersteuning wordt gebruikt.</p>
            <div class="form-row">
                <Result :payload="calculateAmounts(['2.2.beperkt', '2.2.nauwelijks'], ['2.1.beperkt', '2.1.nauwelijks'])" label-title="Aantal patiënten in 'beperkt' en 'nauwelijks'"/>
            </div>
            <p>Aantal pati&euml;nten waarbij glij- of rolmateriaal wordt gebruikt.</p>
            <div class="form-row">
                <Result :payload="calculateAmounts(['2.3.beperkt', '2.3.nauwelijks'], ['2.1.beperkt', '2.1.nauwelijks'])" label-title="Aantal patiënten in 'beperkt' en 'nauwelijks'"/>
            </div>
        </div>

        <h4 class="mt-5">3. Transfers van en naar bed, (rol)stoel etc.</h4>
        <div class="form-row">
            <Result :payload="calculateAmounts(['3.2.beperkt', '3.2.nauwelijks'], ['3.1.beperkt', '3.1.nauwelijks'])" label-title="Aantal patiënten in 'beperkt' en 'nauwelijks'"/>
        </div>

        <h4 class="mt-5">4. Statische belasting (in moeilijke houdingen werken)</h4>
        <div class="form-row">
            <Result label-title="Aantal keer in moeilijke houdingen gewerkt" :payload="{
                sumAmount: amountPatients,
                correct:   amountPatients - this.getSumOf(['4.1']),
                incorrect: this.getSumOf(['4.1'])
            }"/>
        </div>

        <h4 class="mt-5">5. Manoeuvreren met de brancard</h4>
        <div class="form-row">
            <Result label-title="Aantal keer gereden" :payload="calculateAmounts(['5.2'], ['5.1'])"/>
        </div>

        <h4 class="mt-5">6. Tillen van objecten</h4>
        <div class="form-row">
            <Result label-title="Aantal keer getild" :payload="calculateAmounts(['6.2'], ['6.1'])"/>
        </div>


        <button @click="exportData" class="btn btn-primary mt-5 float-right">Download als PDF <i class="fas fa-file-pdf"></i></button>
    </div>
</template>

<script>
import Result from './components/Result';
import Moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
    name:       'Results',
    components: {
        Result
    },
    computed:   {
        service() {
            return this.$store.state.service;
        },
        location() {
            return this.$store.state.location;
        },
        amountPatients() {
            return this.$store.state.amountPatients;
        },
        contactName() {
            return this.$store.state.contactName;
        },
        questions() {
            return this.$store.state.questions;
        }
    },
    data() {
        return {
            currentDate:     new Moment().format('D-M-Y'),
            data:            [{
                name:   'Volgens de praktijkrichtlijnen', chartType: 'bar',
                values: [0, 0, 0, 0, 0, 0, 0]
            }, {
                name:   'Niet volgens de praktijkrichtlijnen', chartType: 'bar',
                values: [0, 0, 0, 0, 0, 0, 0]
            }],
            resultsChartKey: 0
        }
    },
    methods: {
        updateChartData() {
            let correctArray   = [];
            let incorrectArray = [];
            this.$children.forEach(child => {
                if(child.$options.name === 'Result') {
                    if(typeof child.payload !== 'undefined') {
                        correctArray.push(child.payload.correct);
                        incorrectArray.push(child.payload.incorrect);
                    } else {
                        correctArray.push(0);
                        incorrectArray.push(0);
                    }
                }
            });

            this.data = [{
                name:   'Volgens de praktijkrichtlijnen', chartType: 'bar',
                values: correctArray
            }, {
                name:   'Niet volgens de praktijkrichtlijnen', chartType: 'bar',
                values: incorrectArray
            }];

            this.forceRerender();
        },
        exportData(e) {
            e.preventDefault();

            html2canvas(document.body, {
                ignoreElements: function() {
                    document.querySelector('nav').style.display          = 'none';
                    document.querySelector('.logo-footer').style.display = 'none';
                    document.querySelector('noscript').style.display     = 'none';
                },
            }).then(canvas => {
                document.querySelector('nav').style.display          = 'flex';
                document.querySelector('.logo-footer').style.display = 'inline';
                document.querySelector('noscript').style.display     = 'inline';

                let width  = (document.body.scrollWidth * 72 / 96) * window.devicePixelRatio;
                let height = (document.body.scrollHeight * 72 / 96) * window.devicePixelRatio;

                let imgData = canvas.toDataURL('image/png');
                let doc     = new jsPDF('p', 'pt', [width, height], true);
                doc.addImage(imgData, 'PNG', 10, 10, null, null, null, 'MEDIUM');
                doc.save('jouw-resultaten.pdf');
            });
        },
        forceRerender() {
            this.resultsChartKey += 1;
        },
        getSumOf(ids) {
            let result = 0;
            for(let index in ids) {
                let question = this.$store.getters.getQuestionById(ids[index]);
                if(typeof question !== 'undefined') {
                    result += parseInt(question.value);
                }
            }

            return result;
        },
        calculateAmounts(specific, totalSum) {
            let totalSumAmount = this.getSumOf(totalSum);
            let specificAmount = this.getSumOf(specific);

            return {
                sumAmount: parseInt(totalSumAmount),
                correct:   parseInt(specificAmount),
                incorrect: parseInt(totalSumAmount) - parseInt(specificAmount)
            }
        },
        getPercentage(sum) {
            if(isNaN(sum)) {
                return 0;
            }

            return sum;
        }
    },
    mounted() {
        this.updateChartData();
    }
}
</script>
