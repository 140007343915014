<template>
    <div>
        <!-- Nav -->
        <i id="scroll-indicator"></i>
        <i id="top"></i>
        <nav class="navbar navbar-expand-xl" id="mainNav">
            <router-link to="/">
                <img src="img/logo-ambulancezorg.svg" class="logo">
            </router-link>
            <button class="navbar-toggler navbar-toggler-right bg-primary text-white collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                    aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span> </span>
                <span> </span>
                <span> </span>
            </button>
            <div class="collapse navbar-collapse flex-row-reverse" id="navbarResponsive">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/over-de-tilthermometer">Over de TilThermometer</router-link>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
}
</script>
