<template>
    <div>
        <h2>5. Manoeuvreren met de brancard</h2>
        <p><strong>Praktijkrichtlijn Fysieke Belasting:</strong> niet meer dan 20-25 kg bij het in beweging zetten van de brancard en niet meer dan 10 kg bij het in beweging houden van de brancard.</p>
        <form class="mt-5">
            <h4>5.1. Aantal keren dat er is gereden met de brancard</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <NumberField id="5.1"/>
                </div>
            </div>
            <h4>5.2. Het aantal keren dat er gereden is met brancard waarbij de duw- of trekkracht meer was dan 20-25 kg bij het in beweging zetten en/of 10 kg bij het in beweging houden.</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <NumberField id="5.2" :maxValue="this.$store.getters.getQuestionById('5.1').value" showLabel="true"/>
                </div>
            </div>
            <router-link to="/stap-6" class="btn btn-primary mt-5">Volgende stap <i class="fas fa-angle-right"></i></router-link>
        </form>
    </div>
</template>

<script>
import NumberField from "./components/NumberField";

export default {
    name:       'StepFive',
    components: {NumberField},
    methods:    {
        getPercentage(sum) {
            if(isNaN(sum)) {
                return 0;
            }

            return sum;
        }
    }
}
</script>
