<template>
    <div>
        <div class="input-group input-mob-default">
            <div class="input-group-prepend" v-if="showLabel && maxValue > 0">
                <label class="input-group-text" :for="id">{{ ((typeof maxValue !== 'undefined') ? 'Max. ' + maxValue : '') }}</label>
            </div>
            <input type="text" class="form-control" :id="id" placeholder="0" :value="enteredValue" @keyup="validateValue">
        </div>
    </div>
</template>

<script>
export default {
    name:  'NumberField',
    props: ['id', 'maxValue', 'showLabel'],
    data() {
        return {
            enteredValue: 0
        }
    },
    methods: {
        validateValue(event) {
            let value = parseInt(event.target.value);
            if(isNaN(value)) {
                value = 0;
            }

            if(typeof this.maxValue !== 'undefined') {
                if(value > this.maxValue) {
                    value = this.maxValue;
                }
            }

            this.enteredValue  = value;
            event.target.value = value;

            this.$store.commit('setQuestion', {
                id:    this.id,
                value: value
            });
        }
    },
    mounted() {
        let question = this.$store.getters.getQuestionById(this.id);
        if(typeof question !== 'undefined') {
            this.enteredValue = question.value;
        } else {
            this.enteredValue = 0;
        }
    }
}
</script>
