<template>
    <div>
        <section class="pt-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <h2>Over de TilThermometer Ambulancezorg</h2>
                        <div>
                            <p>Met deze TilThermometer© voor de Ambulancezorg kan op dienst, regio en op landelijk niveau in kaart gebracht worden hoe hoog en van welke type de fysieke belasting van de medewerkers is, wat de zorgzwaarte is
                                en hoe succesvol de dienst is met het invoeren van het beleid fysieke belasting.</p>
                            <p>De TilThermometer is in 2002 ontwikkeld door Hanneke Knibbe (LOCOmotion) voor de langdurige zorg en is in het kader van de Arbocatalogus Ambulancezorg online beschikbaar voor de branche.</p>
                            <p>Het gebruik van de TilThermometer komt tegemoet aan het probleem dat de mate van blootstelling aan fysieke overbelasting in de zorg niet valide en betrouwbaar is te meten met directe metingen, observaties of
                                met vragenlijsten (Burdorf & Sorock, 1995). Met de TilThermometer kan dit wel (Knibbe & Friele, 1999). De tool is gevalideerd (Knibbe & Knibbe, 2012) en opgenomen in de CEN ISO TR 12296 (Hignett et al.,
                                2014).</p>
                            <p>Copyright TilThermometer: Knibbe JJ, Knibbe NE (LOCOmotion).</p>
                            <h3>Bronnen</h3>
                            <p class="sub">A.Burdorf, G. Sorock, (1995) Postive and negative evidence of risk factors for back disorders, Scand.J.Work Environment and Health,
                                23:243-256. </p>
                            <p class="sub">Hignett, S., Fray M., Battevi, N., Occhipinti, E., Menoni, O., Tamminen-Peter, L., Waaijer, E., Knibbe, H.J.J., Jäger, M. (2014).
                                International consensus on manual handling of people in the healthcare sector: Technical report ISO/TR 12296; 44 (1) :191–195.</p>
                            <p class="sub">Knibbe J.J., N.E. Knibbe. An international validation study of the care thermometer: a tool supporting the quality of ergonomic policies in
                                health care. Work 41 (2012) 5639-5641 DOI: 10.3233/WOR-2012-0903-5639 IOS Press. </p>
                            <p class="sub">Knibbe, J.J., R.D.Friele, Ergonomics, 1996 Prevalence of back pain and characterisitcs of the physical workload of community nurses,
                                Ergonomics, 39, 2, 1996, 186-198. </p>
                            <p class="sub">Knibbe, J.J., R.D.Friele, The use of logs to assess exposure to manual handling of patients, International Journal of Industrial Ergonomics
                                24, 445-454, 1999.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>
