<template>
    <div :class="'input-group input-mob-' + mobilityClass">
        <div class="input-group-prepend">
            <label class="input-group-text" :for="id"><img src="/img/mob-a.svg" style="width:33px;" v-if="mobilityClass === 'goed'"> <img src="/img/mob-c.svg" style="width:33px;" v-if="mobilityClass === 'beperkt'"> <img src="/img/mob-e.svg" style="width:33px;" v-if="mobilityClass === 'nauwelijks'"> <span v-if="(typeof maxValue !== 'undefined')" class="color-white ml-2 sub">(Max. {{ maxValue }})</span></label>
        </div>
        <input type="text" class="form-control" :id="id" placeholder="0" v-model.number="field">
    </div>
</template>

<script>
export default {
    name:     'MobilityClassAnswer',
    props:    ['id', 'title', 'mobilityClass'],
    computed: {
        field: {
            get() {
                let question = this.$store.getters.getQuestionById(this.id);
                if(typeof question !== 'undefined') {
                    return question.value;
                }

                return 0;
            },
            set(value) {
                if(value !== '') {
                    let splitted = this.id.split('.');
                    splitted[1]  = '1';
                    let firstRow = splitted.join('.');

                    if(firstRow !== this.id) {
                        let question = this.$store.getters.getQuestionById(firstRow);
                        if(typeof question === 'undefined' || value > question.value) {
                            value = question.value;
                        }
                    }

                    if(firstRow === this.id) {
                        splitted[1]++;
                        while(typeof this.$store.getters.getQuestionById(splitted.join('.')) !== 'undefined') {
                            let answer = this.$store.getters.getQuestionById(splitted.join('.'));
                            if(value < answer.value) {
                                this.$store.commit('setQuestion', {
                                    id:    splitted.join('.'),
                                    value: value
                                });
                                answer.value = value;
                            }
                            splitted[1]++;
                        }

                        if(typeof this.maxValue !== 'undefined' && value > this.maxValue) {
                            value = this.maxValue;
                        }
                    }

                    this.value = value;

                    this.$store.commit('setQuestion', {
                        id:    this.id,
                        value: value
                    });
                } else {
                    value      = 0;
                    this.value = value;

                    this.$store.commit('setQuestion', {
                        id:    this.id,
                        value: value
                    });
                }
            }
        }
    }
}
</script>
