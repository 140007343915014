<template>
    <div class="footer">
        <!-- footer -->
        <section class="bg-secondary">
            <div class="container">
                <div class="row text-center mb-2">
                    <div class="col-md-12">
                        <img src="/img/logo.svg" class="logo-footer img-fluid">
                        <div class="mb-4"></div>
                    </div>
                </div>
                <div class="row text-center mb-4">
                    <div class="col-md-12">
                        <span class="sub"></span>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-lg-6 mx-auto">
                        <div class="row flex-row justify-content-center">
                            <div class="col-lg-3 col-md-4 col-5">
                                <img class="img-fluid footer-logo rounded" src="/img/logo-locomotion.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="overflow-visible ">
            <div class="container">
                <div class="row v-align-children">
                    <div class="col-lg-12 text-center">
                        <span class="sub-sub"><router-link to="/copyright">Copyright {{ year }} LOCOmotion </router-link> | <router-link
                            to="/privacyverklaring">Privacyverklaring</router-link> | <a href="https://benedenboven.nl" target="_blank">Realisatie BenedenBoven</a></span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            'year': (new Date).getFullYear()
        }
    }
}
</script>
