<template>
    <div>
        <!-- Header -->
        <section class="pt-0">
            <div class="container bg-gradient">
                <div class="header-content">
                    <div class="row">
                        <div class="col-md-12 header-title">
                            <h4>Fysieke overbelasting van zorgmedewerkers vaststellen</h4>
                            <h1 class="mb-0">TilThermometer Ambulancezorg</h1>
                        </div>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div class="row" v-if="showMobilityClassesOn.includes(this.$route.name)">
                            <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                                <div class="explain-mob-classes">
                                    <span class="color-body">Uitleg van de Mobiliteitsklassen</span>
                                    <div class="d-flex justify-content-center align-items-center mt-4">
                                        <div class="flex-fill tooltip-a p-1" data-toggle="tooltip" data-placement="bottom"
                                             title="De patiënt kan vrijwel zelfstandig (op)staan en lopen, maar is bijvoorbeeld onzeker.">
                                            <img class="mob-class-img" src="/img/mob-a.svg">
                                            <h4 class="mb-0 light">Goed
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)"/>
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                        <div class="flex-fill p-1" data-toggle="tooltip" data-placement="bottom"
                                             title="De patiënt kan niet zelfstandig (op)staan, heeft enige rompbalans en kan enigszins steun nemen op een of beide benen en weegt meer dan 23 kg.">
                                            <img class="mob-class-img" src="/img/mob-c.svg">
                                            <h4 class="mb-0 light">Beperkt
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)"/>
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                        <div class="flex-fill p-1" data-toggle="tooltip" data-placement="bottom"
                                             title="De patiënt heeft onvoldoende rompbalans en kan geen steun nemen op de benen en weegt meer dan 23 kg.">
                                            <img class="mob-class-img" src="/img/mob-e.svg">
                                            <h4 class="mb-0 light">Nauwelijks
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)"/>
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import jQuery from 'jquery';

export default {
    name: 'Header',
    data: function() {
        return {
            showMobilityClassesOn: ['home', 'stepOne', 'stepTwo', 'stepThree', 'stepFour', 'stepFive', 'stepSix', 'results']
        }
    },
    mounted() {

        const vm = this;
        this.$router.afterEach(() => {
            if(vm.showMobilityClassesOn.includes(vm.$route.name)) {
                vm.$nextTick(() => {
                    jQuery('[data-toggle="tooltip"]').tooltip('dispose');
                    jQuery('[data-toggle="tooltip"]').tooltip();
                });
            }
        });

        window.addEventListener('click', (event) => {
            if(event.target.classList.contains('flag')) {
                jQuery('[data-toggle="tooltip"]').tooltip('dispose');
                jQuery('[data-toggle="tooltip"]').tooltip();
            }
        });
    }
}
</script>
