<template>
    <div>
        <h2>6. Tillen van objecten</h2>
        <p><strong>Praktijkrichtlijn Fysieke Belasting:</strong> Bij af en toe tillen: niet meer dan 23 kg tillen. Bij vaker dan 12 keer per dienst tillen: niet meer dan 12 kg.</p>
        <form class="mt-5">
            <h4>6.1. Het totaal aantal keren dat er objecten zijn getild</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <NumberField id="6.1"/>
                </div>
            </div>
            <h4>6.2. het aantal keer dat er meer dan 23 kg is getild (bij af en toe tillen), en/of meer dan 12 kg (bij vaker dan 12 keer per dienst tillen).</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <NumberField id="6.2" :maxValue="this.$store.getters.getQuestionById('6.1').value" showLabel="true"/>
                </div>
            </div>
            <router-link to="/resultaten" class="btn btn-primary mt-5">Bekijk jouw resultaten <i class="fas fa-angle-right"></i></router-link>
        </form>
    </div>
</template>

<script>
import NumberField from "./components/NumberField";

export default {
    name:       'StepSix',
    components: {NumberField},
    methods:    {
        getPercentage(sum) {
            if(isNaN(sum)) {
                return 0;
            }

            return sum;
        }
    }
}
</script>
