<template>
    <div>
        <h2>1. Transfers binnen de grenzen van de ligondersteuning (bed, brancard, etc.) of op de grond</h2>
        <p><strong>Praktijkrichtlijn Fysieke Belasting:</strong> Bij patiënten met Mobiliteitsklasse 'Beperkt' (oranje) of 'Nauwelijks' (rood) wordt glij- of rolmateriaal en een in hoogte verstelbare ligondersteuning gebruikt.</p>
        <form class="mt-5">
            <h4>1.1. Het aantal keer dat deze transfer is uitgevoerd per mobiliteitsklasse</h4>
            <p>Per patiënt kan meerdere keren sprake zijn van een transfer binnen de grenzen van de ligondersteuning, of op de grond. Tel per Mobiliteitsklasse hoe vaak deze transfer is uitgevoerd. Dit aantal kan dus hoger zijn dan het
                aantal patiënten in betreffende Mobiliteitsklasse.</p>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.1.goed" title="Goed" mobilityClass="goed"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.1.beperkt" title="Beperkt" mobilityClass="beperkt"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.1.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"/>
                </div>
            </div>
            <h4>1.2. Aantal van deze transfers waarbij een in hoogte verstelbare ligondersteunig is gebruikt</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.2.goed" title="Goed" mobilityClass="goed" :maxValue="this.$store.getters.getQuestionById('1.1.goed').value - this.$store.getters.getQuestionById('1.3.goed').value"/>
                </div>
                <div class="col-md-4 4 mb-3">
                    <MobilityClassAnswer id="1.2.beperkt" title="Beperkt" mobilityClass="beperkt" :maxValue="this.$store.getters.getQuestionById('1.1.beperkt').value - this.$store.getters.getQuestionById('1.3.beperkt').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.2.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"
                                         :maxValue="this.$store.getters.getQuestionById('1.1.nauwelijks').value - this.$store.getters.getQuestionById('1.3.nauwelijks').value"/>
                </div>
            </div>
            <h4>1.3. Aantal van deze transfers waarbij glij- of rolmateriaal is gebruikt</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.3.goed" title="Goed" mobilityClass="goed" :maxValue="this.$store.getters.getQuestionById('1.1.goed').value - this.$store.getters.getQuestionById('1.2.goed').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.3.beperkt" title="Beperkt" mobilityClass="beperkt" :maxValue="this.$store.getters.getQuestionById('1.1.beperkt').value - this.$store.getters.getQuestionById('1.2.beperkt').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="1.3.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"
                                         :maxValue="this.$store.getters.getQuestionById('1.1.nauwelijks').value - this.$store.getters.getQuestionById('1.2.nauwelijks').value"/>
                </div>
            </div>
            <router-link to="/stap-2" class="btn btn-primary mt-5">Volgende stap <i class="fas fa-angle-right"></i></router-link>
        </form>
    </div>
</template>
<script>
import MobilityClassAnswer from './components/MobilityClassAnswer';

export default {
    name:       'StepOne',
    components: {
        MobilityClassAnswer
    },
    methods:    {
        getPercentage(sum) {
            if(isNaN(sum)) {
                return 0;
            }

            return sum;
        }
    }
}
</script>
