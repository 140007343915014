<template>
    <div>
        <h2>2. Horizontale transfers van de ene naar de andere ligondersteuning</h2>
        <p><strong>Praktijkrichtlijn Fysieke Belasting:</strong> Bij transfers met patiënten met Mobiliteitsklasse 'Beperkt' (oranje) of 'Nauwelijks' (rood) zijn de beide ligondersteuningen bij voorkeur in hoogte verstelbaar. Daarnaast
            wordt er gebruik gemaakt van rol- of glijmateriaal, of een tillift.</p>
        <form class="mt-5">
            <h4>2.1. Het aantal keer dat deze transfer is uitgevoerd per Mobiliteitsklasse</h4>
            <p>Het kan zijn dat er per patient sprake is van meer dan een transfer van de ene naar de andere ligondersteuning. Tel het aantal transfers per Mobiliteitsklasse. Dit aantal kan dus hoger zijn dan het aantal patiënten in
                dezelfde Mobiliteitsklasse.</p>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.1.goed" title="Goed" mobilityClass="goed"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.1.beperkt" title="Beperkt" mobilityClass="beperkt"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.1.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"/>
                </div>
            </div>
            <h4>2.2. Aantal van deze transfers waarbij beide ligondersteuningen in hoogte verstelbaar waren</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.2.goed" title="Goed" mobilityClass="goed" :maxValue="this.$store.getters.getQuestionById('2.1.goed').value - this.$store.getters.getQuestionById('2.3.goed').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.2.beperkt" title="Beperkt" mobilityClass="beperkt" :maxValue="this.$store.getters.getQuestionById('2.1.beperkt').value - this.$store.getters.getQuestionById('2.3.beperkt').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.2.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"
                                         :maxValue="this.$store.getters.getQuestionById('2.1.nauwelijks').value - this.$store.getters.getQuestionById('2.3.nauwelijks').value"/>
                </div>
            </div>
            <h4>2.3. Aantal van deze transfers waarbij glij- of rolmateriaal of een tillift is gebruikt</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.3.goed" title="Goed" mobilityClass="goed" :maxValue="this.$store.getters.getQuestionById('2.1.goed').value - this.$store.getters.getQuestionById('2.2.goed').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.3.beperkt" title="Beperkt" mobilityClass="beperkt" :maxValue="this.$store.getters.getQuestionById('2.1.beperkt').value - this.$store.getters.getQuestionById('2.2.beperkt').value"/>
                </div>
                <div class="col-md-4 mb-3">
                    <MobilityClassAnswer id="2.3.nauwelijks" title="Nauwelijks" mobilityClass="nauwelijks"
                                         :maxValue="this.$store.getters.getQuestionById('2.1.nauwelijks').value - this.$store.getters.getQuestionById('2.2.nauwelijks').value"/>
                </div>
            </div>
            <router-link to="/stap-3" class="btn btn-primary mt-5">Volgende stap <i class="fas fa-angle-right"></i></router-link>
        </form>
    </div>
</template>

<script>
import MobilityClassAnswer from "./components/MobilityClassAnswer";

export default {
    name:       'StepTwo',
    components: {MobilityClassAnswer},
    methods:    {
        getPercentage(sum) {
            if(isNaN(sum)) {
                return 0;
            }

            return sum;
        }
    }
}
</script>
