<template>
    <div>
        <h2>4. Statische belasting (in moeilijke houdingen werken)</h2>
        <p><strong>Praktijkrichtlijn Fysieke Belasting:</strong> niet langer dan 1 minuut met meer dan 30 graden gedraaide, en/of voor- of zijwaarts gebogen romp werken.</p>
        <form class="mt-5">
            <h4>4.1. Aantal patiënten waarbij personeel langer dan 1 minuut met meer dan 30 graden gedraaide, en/of voor- of zijwaarts gebogen romp heeft gewerkt.</h4>
            <div class="form-row mb-4">
                <div class="col-md-4 mb-3">
                    <NumberField id="4.1" :maxValue="this.$store.state.amountPatients" showLabel="true"/>
                </div>
            </div>
            <router-link to="/stap-5" class="btn btn-primary mt-5">Volgende stap <i class="fas fa-angle-right"></i></router-link>
        </form>
    </div>
</template>

<script>
import NumberField from "./components/NumberField";

export default {
    name:       'StepFour',
    components: {NumberField},
    methods:    {
        getPercentage(sum) {
            if(isNaN(sum)) {
                return 0;
            }

            return sum;
        }
    }
}
</script>
